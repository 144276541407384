import axios from 'axios';
import Cookies from 'universal-cookie/es6';
import { ApiUrl } from '../../services/ApiRest';
import {checkingCredentials, login,logout} from './authSlice';
// import ApiUrl from '../../services/ApiRest'
const url=ApiUrl;
const cookies = new Cookies;

export const chekingAutentication=(email,password)=>{
    return async (dispatch)=>{
        dispatch(checkingCredentials() )

        await axios.post(ApiUrl+"login",{
        email:email,
        password:password,
        })
        .then(response =>{
            const result = response.data           
            console.log("result::");
            console.log(result);
            if(result.success === true){                
                cookies.set('uid',result.user.id,{path:"/"})
                cookies.set('token',result.accessToken,{path:"/"})
                // var rol = cookies.set('rol',result.rol,{path:"/"})
                // dispatch(login(result));
                console.log("Este es el Rol:" + result.rol)
                if(result.rol === '3f7be9c1-997b-42f1-b0b5-58da7de1f37c'){
                    window.location.href="/administrativo";
                }else{
                    if(result.rol==='client'){
                        window.location.href="/client";
                    }else{
                        if(result.rol==='a930c4ac-462c-4d05-bacd-a2691ae6e0cc'){
                            window.location.href="/guide";
                        }else{
                            if(result.rol==='9acf6630-8ab4-41bb-a5e4-477221ef820d'){
                                window.location.href="/bodeguero";
                            }else{
                                if(result.rol==='c642c1be-81a7-4bfb-bc85-c04238b93857'){
                                    window.location.href="/subscriber";
                                }else{
                                    
                                } 
                            }

                        }
                    }
                }
            }else{
                dispatch(logout(result));
                // window.location.href="/iniciar-sesion";
            }
        })
        .catch(error =>{
            console.log(error);
            dispatch(logout(error));
        })
    }
}

export const createUser = (formState)=>{
    return async (dispatch)=>{
        console.log("este es ");
        console.log(formState);
        await axios.post(ApiUrl+"register",{
            name:formState.name,
            last_name:formState.last_name,
            cellphone:formState.cellphone,
            email:formState.email,
            password:formState.password,
            password_confirmation:formState.password_confirm,
            rol:'client'
        })
        .then(response =>{
            const result = response.data
            console.log("usuario registrado")
            console.log(result)
            
            dispatch(login(result));
        })
        .catch(error =>{
            console.log(error);
        })
    }
}

export const logoutSesion = (AccessToken)=>{
    return async (dispatch)=>{
        var config = {
            headers: {
              'Authorization': 'Bearer ' + AccessToken
            }
        }
        console.log(config);
        await axios.post(ApiUrl+ "logout",{},config)
        .then(response =>{
            const result = response.data
            cookies.remove('uid',{path:"/"});
            cookies.remove('token',{path:"/"});
            cookies.remove('rol',{path:"/"});
            console.log("SESION CERRADA")
            console.log(result)
            dispatch(logout(result));
            window.location.href="/iniciar-sesion";



        })
        .catch(error =>{
            console.log(error);
        })
    }
}

export const loadUser = (id)=>{
    // console.log("aa@"+id)
    return async (dispatch)=>{

        await axios.get(url+'user_info/'+id)
        .then(response =>{
            const result = response.data;
            dispatch(login(result));

        })
        .catch(e=>{
            console.log(e);
        })        
    }
}