import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useGetDate } from '../../hooks/useGetDate';
import { ApiStorage, ApiUrl } from '../../services/ApiRest';
import { FooterScreen } from '../footer/FooterScreen';
import { NavBarScreen } from '../navBar/NavBarScreen';
import { WhatsappButtonScreen } from './whatsappButton/WhatsappButtonScreen';

export const AllToursScreen = () => {
    localStorage.removeItem("menu");
    window.localStorage.setItem("menu", 3);

    const { printMonth } = useGetDate();
    console.log(printMonth);

    const [tour, settour] = useState([]);
    const [selectedType, setSelectedType] = useState(""); // Estado para el tipo seleccionado

    const getData = async () => {
        try {
            const response = await axios.get(ApiUrl + "catalogue-list");
            settour(response.data);
        } catch (e) {
            console.error(e);
        }
    };

    const textLimit = (text, limit) => {
        return text.length > limit ? text.substring(0, limit) + "..." : text;
    };

    useEffect(() => {
        getData();
    }, []);

    // Tours filtrados según el tipo seleccionado
    const filteredTours = selectedType
        ? tour.filter((t) => t.type === selectedType)
        : tour;

    return (
        <>
            <NavBarScreen />
            <div className="cont_img animate__animated  animate__fadeIn">
                <div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">
                    <div className="carousel-inner" role="listbox">
                        <div
                            className="banner"
                            data-bs-interval="4000"
                            style={{ backgroundImage: `url('./assets/images/principal/tours/at1.jpeg')` }}
                        >
                            <div className="pantalla_dividida" style={{ height: "45vh" }}>
                                <div className="text_cent_img_dividida animate__animated animate__fadeInRight animate__delay-0.8s">
                                    <h1 className="tit-sob-img_dividida" style={{ textShadow: '1px 1px 2px rgba(0,0,0,1.5)' }}>
                                        Encuentra la ruta<br />que más te guste
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <br />
            <div className="container">
                <h1 className="camping-letters text-center">TODOS NUESTROS TOURS</h1>
                <p style={{ textAlign: 'center' }}>
                    Saludos amigo aventurero! aquí encontrarás información de todos los tours que tenemos disponibles, estos pueden ser campings, full days o cumbres.
                    Selecciona la ruta que más te guste y escríbenos para cuadrar una fecha. Te esperamos para hacer la ruta que desees.
                </p>
            </div>

            {/* Select para filtrar */}
            {/* <div className="d-flex justify-content-center align-items-center">
                <div className="col-12 col-sm-3">
                    <div className="form-group text-center">
                        <select
                            className="form-select"
                            name="type"
                            aria-label="Default select example"
                            value={selectedType}
                            onChange={(e) => setSelectedType(e.target.value)} // Actualiza el tipo seleccionado
                        >
                            <option value="">Todos los tipos</option>
                            <option value="Full Day">Full Day</option>
                            <option value="Camping">Camping</option>
                            <option value="Refugio">Descanso en Refugio</option>
                            <option value="Intento de Cumbre">Intento de Cumbre</option>
                            <option value="Cumbre">Cumbre</option>
                        </select>
                    </div>
                </div>
            </div> */}

            {/* Select para filtrar */}
            <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center">
                {/* <div className="form-group text-center d-flex align-items-center"> */}
                {/* Anuncio */}
                <label className="mb-2 mb-sm-0 me-sm-2 d-flex align-items-center">Seleccione categoría:</label>
                {/* Select */}
                <select
                    className="form-select"
                    name="type"
                    aria-label="Default select example"
                    value={selectedType}
                    onChange={(e) => setSelectedType(e.target.value)} // Actualiza el tipo seleccionado
                    style={{ width: 'auto' }}
                >
                    <option value="">Todos los tipos</option>
                    <option value="Full Day">Full Day</option>
                    <option value="Camping">Camping</option>
                    <option value="Descanso en Refugio">Descanso en Refugio</option>
                    {/* <option value="Intento de Cumbre">Intento de Cumbre</option>
                    <option value="Cumbre">Cumbre</option> */}
                </select>
                {/* </div> */}
            </div>
            <br></br>
            <div className="container">
                {
                    filteredTours.length > 0 ? (
                        filteredTours.map((tour, index) => (
                            <div key={tour.tour_catalogues_id}>
                                <div className="card mb-12">
                                    <div className="row g-0">
                                        <div className="col-md-3 text-center">
                                            <img
                                                src={`${ApiStorage + tour.img_1}`}
                                                style={{ width: '80%', padding: '1.5vh' }}
                                                className="card-img-top"
                                                alt="..."
                                            />
                                        </div>
                                        <div className="col-md-7">
                                            <div className="card-body">
                                                <h5 className="card-title camping-letters t-name">{tour.tour_name}</h5>
                                                <h4 className="card-title camping-letters t-destiny" style={{ textTransform: 'uppercase' }}>
                                                    {tour.tour_destiny}
                                                </h4>
                                                <p className="card-text" style={{ textAlign: 'justify' }}>
                                                    {textLimit(tour.description, 165)}
                                                </p>
                                                <p className="card-text">
                                                    <small className="text-muted">Nivel: {tour.dificulty}</small>
                                                    <small className="text-muted" style={{ paddingLeft: "10%" }}>
                                                        Duración: {tour.type === "Full Day" ? "1 día" : "2 días y 1 noche"}
                                                    </small>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-2 d-flex align-items-center">
                                            <div className="card-body text-center img-list-at">
                                                <h5 className="card-title camping-letters">Desde:</h5>
                                                <h1 className="card-text camping-letters">{tour.cost_4}</h1>
                                                <Link to={`/tour-disponibles/${tour.tour_catalogues_id}`}>
                                                    <button type="button" className="btn btn-outline-success">
                                                        <b>Ver Más</b>
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                            </div>
                        ))
                    ) : (
                        <div className="text-center">
                            <h3>No existe ningún tour de este tipo</h3>
                        </div>
                    )
                }
            </div>
            <WhatsappButtonScreen />
            <FooterScreen />
        </>
    );
};
