import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { ApiUrl } from '../../../services/ApiRest';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
// import { BrowserRouter as Router } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie/es6';
const cookies = new Cookies();
const userId = cookies.get('uid');
// import { BrowserRouter as Router } from 'react-router-dom';
// console.log('USER ID', userId);

export const SalesFree = () => {

    const navigate = useNavigate();
    const [showForm, setShowForm] = useState(false);
    const [showForm2, setShowForm2] = useState(false);
    const [showForm3, setShowForm3] = useState(true);
    const [showForm4, setShowForm4] = useState(false);
    const [showForm5, setShowForm5] = useState(false);
    const [showForm6, setShowForm6] = useState(false);
    const [showForm7, setShowForm7] = useState(true);
    const [showForm8, setShowForm8] = useState(true);
    const [showForm9, setShowForm9] = useState(true);
    const [showForm10, setShowForm10] = useState(false);
    const [showForm11, setShowForm11] = useState(true);
    const [reloadChild, setReloadChild] = useState(false);
    const [selectDisabled, setselectDisabled] = useState(true);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [total, setTotal] = useState(0);
    const [data, setdata] = useState([]);
    const [resetSelect, setResetSelect] = useState(false);
    const [passengerCiValue, setpassengerCiValue] = useState();
    const [passengerStatusValue, setpassengerStatusValue] = useState();
    const [productToDelete, setProductToDelete] = useState(null);
    const [productSelected, setproductSelected] = useState([]);
    const [passengerCiFinalConsumer, setpassengerCiFinalConsumer] = useState();
    const [ciFinalConsumer, setciFinalConsumer] = useState(9999999999);
    const [secuentialNew, setsecuentialNew] = useState();
    const [secuentialBillId, setsecuentialBillId] = useState();
    const [secuentialBillIdInProcess, setsecuentialBillIdInProcess] = useState();
    const [secuentialBillCiInProcess, setsecuentialBillCiInProcess] = useState();
    const [passengerSFValue, setpassengerSFValue] = useState();
    const [passengerSaleTypeValueDataList, setpassengerSaleTypeValueDataList] = useState();
    const [passengerSaleTypeValueDataList2, setpassengerSaleTypeValueDataList2] = useState();
    const [passengerStatusValueDataList, setpassengerStatusValueDataList] = useState();
    const [passengerSFValueDataList, setpassengerSFValueDataList] = useState();
    const [passengerCiValueDataList, setpassengerCiValueDataList] = useState();
    const [formD, setformD] = useState({
        ci: '',
        name: '',
        phone: '',
        address: '',
        correo: ''
    });
    const [formD2, setformD2] = useState({
        price_sale_free: '',
        description_sale_free: ''
    });

    const onSearch = async () => {
        setButtonDisabled(true);
        const formData = new FormData();
        formData.append("ci", formD.ci);
        // Verificar el valor de formD.ci
        if (formD.ci === '' || formD.ci === null) {
            toast.error("Ingrese un número de cédula", { position: toast.POSITION.BOTTOM_RIGHT });
        } else {
            // console.log('Valor de ci en formD:', formD.ci);
            try {
                const response = await axios.post(`${ApiUrl}passenger-sale-products-search`, { ci: formD.ci });
                const resp = response.data;
                console.log('Resultado de la búsqueda:', resp);
                if (resp && resp.passenger) {
                    console.log('Nombre del pasajero:', resp.passenger.name);
                    console.log('Valor passengerCiValue', resp.passenger.ci);
                    // if (formD.name === '' || formD.phone === '' || formD.address === '' || formD.correo === '') {
                    if ((resp.passenger.name === null || resp.passenger.phone == null || resp.passenger.address === null || resp.passenger.correo === null) || (resp.passenger.name === '' || resp.passenger.phone == '' || resp.passenger.address === '' || resp.passenger.correo === '')) {
                        // Actualizar el estado utilizando una función de actualización del estado
                        setformD(prevFormD => ({
                            ...prevFormD,
                            name: resp.passenger.name,
                            phone: resp.passenger.phone,
                            address: resp.passenger.address,
                            correo: resp.passenger.correo
                        }));
                        setButtonDisabled(false);
                        dataList();
                        // setpassengerExistCi(resp.passenger.passenger_id);
                        console.log('Valor de passenger_idsdsds', resp.passenger.passenger_id);
                        setShowForm7(false);
                        setShowForm6(false);

                    } else {

                        // Actualizar el estado utilizando una función de actualización del estado
                        setformD(prevFormD => ({
                            ...prevFormD,
                            name: resp.passenger.name,
                            phone: resp.passenger.phone,
                            address: resp.passenger.address,
                            correo: resp.passenger.correo
                        }));
                        setButtonDisabled(true);
                        dataList();
                        console.log('Valor de passenger_idsdsds TODOOOO:', resp.passenger.passenger_id);
                        setShowForm7(false);
                        setShowForm6(false);
                        setShowForm8(false);
                    }
                    setShowForm(true);

                } else {
                    toast.error("Cliente no registrado en el sistema. Ingrese su información", { position: toast.POSITION.BOTTOM_RIGHT });
                    // Limpiar los campos del formulario
                    setformD(prevFormD => ({
                        ...prevFormD,
                        name: '',
                        phone: '',
                        address: '',
                        correo: ''
                    }));
                    setButtonDisabled(false);
                    setShowForm(false);
                }

                if (resp && resp.passenger) {
                    const ciSecuentialBill = formD.ci;
                    console.log('passengerCiValue SSSSS FREE:', ciSecuentialBill);
                    console.log('passenger SALE TYPE Value SSSSS FREE:', passengerSaleTypeValueDataList);
                    const formData = new FormData();
                    formData.append("description", `Fact`);
                    // formData.append("passengerCiValue", ciSecuentialBill); // Nombre para el valor de passengerCiValue passengerCiValue
                    formData.append("ciSecuentialBill", ciSecuentialBill); // Nombre para el valor de passengerCiValue
                    formData.append("passengerStatusValue", passengerStatusValue); // Nombre para el valor de passengerStatusValue
                    console.log('passengerCiValue AAAA FREE:', passengerCiValue);
                    console.log('passengerCiValue AAAA FREE:', passengerStatusValue);
                    console.log('FREEE Datos enviados al backend AGREGADOSSSS secuential_bill:', Object.fromEntries(formData));

                    const response = await axios.post(ApiUrl + 'free-secuential-bill-sale-products-create', formData);
                    const resp = response.data;
                    console.log('Valor FACT IN :', resp);
                    setsecuentialBillId(resp.secuential_bill_id);
                    setsecuentialBillIdInProcess(resp.valueClientInProcess);
                    setsecuentialBillCiInProcess(resp.valueCIClientInProcess);
                    setsecuentialNew(resp.valueClientInProcess);
                    console.log('IDnewinsertSecuentia Bill:', resp.secuential_bill_id);
                    console.log('valueClientInProcess Secuentia Bill:', resp.valueClientInProcess);
                    console.log('valueCIClientInProcess Secuentia Bill CI:', resp.valueCIClientInProcess);
                    console.log('onSEARCH P:', passengerStatusValue);
                }

            } catch (error) {
                console.log(error);
                toast.error("" + error + "  !", { position: toast.POSITION.BOTTOM_RIGHT });
            }
        }

    };

    const getFinalConsumerInfo = async () => {
        try {
            const response = await axios.post(`${ApiUrl}passenger-sale-products-search-consumer`, { ci: 9999999999 });
            const resp = response.data;
            console.log('consumidor devuelve FREE:', resp);
            console.log('consumidor devuelve 22 FREE:', resp.passenger.ci);
            if (resp.passenger.ci) {
                setformD(prevFormD => ({
                    ...prevFormD,
                    ci: resp.passenger.ci,
                    name: resp.passenger.name,
                    phone: resp.passenger.phone,
                    address: resp.passenger.address,
                    correo: resp.passenger.correo
                }));

                console.log('quiero ver comsumidor DATA:', data);
                setButtonDisabled(true);
                dataList();
                setShowForm7(true);
                setShowForm6(false);
                setShowForm8(false);
                setShowForm2(false);
                setShowForm5(false);
                setShowForm10(true);
                console.log('ci consumidor:', resp.passenger.ci);
                setpassengerCiFinalConsumer(resp.passenger.ci);
                setShowForm6(false);
            } else {
                toast.error("No se encontró información del consumidor final", { position: toast.POSITION.BOTTOM_RIGHT });
            }
            var response2 = '';
            if (resp.passenger.ci) {
                response2 = await axios.post(`${ApiUrl}passenger-sale-products-search-consumer-final`, { ci: 9999999999 });
                console.log('consumidor devuelve 99999 si EXISTE:', response2);
            }
            if (response2.data.passenger2 === null) {
                console.log('No hay consumidores en proceso FREE');
            } else {
                const fsID = response2.data.passenger2.secuential_bill_id;
                console.log('fsID:', fsID);
                const fsStatus = response2.data.passenger2.status_sales_products;
                console.log('fsStatus:', fsStatus);
                const fsCi = response2.data.passenger2.passengers_ci;
                console.log('fsCi:', fsCi);
                const fsSaleType = response2.data.passenger2.sale_type;
                console.log('fsSaleType:', fsSaleType);
                if (fsCi === 9999999999 || fsStatus === 'P' || fsSaleType === 'Libre') {
                    // if (fsCi === 9999999999 || fsStatus === 'P' ) {
                    toast.error("Consumidor Final en proceso", { position: toast.POSITION.BOTTOM_RIGHT });
                    setShowForm(false);
                    setShowForm6(false);
                    setShowForm7(true);
                    setShowForm10(false);
                    setShowForm11(true);
                    return;
                } else {
                    setShowForm6(false);
                    setShowForm7(false);
                }
            }
        } catch (error) {
            console.log(error);
            toast.error("" + error + "  !", { position: toast.POSITION.BOTTOM_RIGHT });
        }
    };

    const onSearchFinalConsumer = async () => {
        const statusForFC = passengerStatusValue;
        console.log('statusForFC909090:', statusForFC);
        const ciForFC = passengerCiValue;
        console.log('CI PARA CONSUMIDOR90909', ciForFC);
        console.log('CI PARA CONSUMIDOR 55555', ciFinalConsumer);
        console.log('secuentialBillProcces909090909090909090', secuentialNew);
        dataList();
        setButtonDisabled(true);
        getFinalConsumerInfo();
    };

    const onInputChange = ({ target }) => {
        const { name, value } = target;
        setformD({
            ...formD,
            [name]: value
        })
    }

    const onInputChange2 = (event) => {
        setformD2({
            ...formD2,
            [event.target.name]: event.target.value,
        });
    };

    const onSubmit = async (event) => {

        event.preventDefault();

        const formData = new FormData();
        formData.append("ci", formD.ci);
        formData.append("name", formD.name);
        formData.append("phone", formD.phone);
        formData.append("address", formD.address);
        formData.append("correo", formD.correo);
        formData.append("city", 'N/A');
        // Verificar el valor de formD.ci
        console.log('Valor de ci en formD:', formD.ci);
        try {
            const response = await axios.post(ApiUrl + 'passenger-sale-products-create', formData);
            const resp = response.data;
            console.log('Valor :', resp);

            if (resp.success) {
                toast.success("Cliente agregado exitosamente", { position: toast.POSITION.BOTTOM_RIGHT });
                setButtonDisabled(true);
                setShowForm(true);
                // setpassengerNewCi(resp.passenger_ci);
                // console.log('Valor de passenger_id Nuevo', resp.passenger_id);

            } else {
                toast.error("Cliente NO se ha agregado", { position: toast.POSITION.BOTTOM_RIGHT });
            }
            if (resp && resp.passenger_id) {
                const ciSecuentialBill = formD.ci;
                console.log('passengerCiValue SSSSS NUEVO CLIENT:', ciSecuentialBill);
                const formData = new FormData();
                formData.append("description", `Fact`);
                formData.append("passengerCiValue", ciSecuentialBill); // Nombre para el valor de passengerCiValue
                formData.append("passengerStatusValue", passengerStatusValue); // Nombre para el valor de passengerStatusValue
                console.log('passengerCiValue AAAA NUEVO CLIENT:', passengerCiValue);
                console.log('passengerCiValue AAAA NUEVO CLIENT:', passengerStatusValue);
                console.log('Datos enviados al backend AGREGADOSSSS secuential_bill NUEVO CLIENT:', Object.fromEntries(formData));

                const response = await axios.post(ApiUrl + 'secuential-bill-sale-products-create', formData);
                const resp = response.data;
                console.log('Valor FACT IN :', resp);
                setsecuentialBillId(resp.secuential_bill_id);
                setsecuentialBillIdInProcess(resp.valueClientInProcess);
                setsecuentialBillCiInProcess(resp.valueCIClientInProcess);
                console.log('IDnewinsertSecuentia Bill Consumi:', resp.secuential_bill_id);
                console.log('valueClientInProcess Secuentia Bill NUEVO CLIENT IN PROCESO:', resp.valueClientInProcess);
                console.log('valueClientInProcess Secuentia Bill NUEVO CLIENT IN PROCESO:', resp.valueClientInProcess);
                console.log('valueCIClientInProcess Secuentia Bill CI NUEVO CLIENT IN PROCESO:', resp.valueCIClientInProcess);
                console.log('onSEARCH P NUEVO CLIENT:', passengerStatusValue);
                // setShowForm12(true);
            }
        } catch (error) {
            console.log(error);
            toast.error("" + error + "!", { position: toast.POSITION.BOTTOM_RIGHT });
        }
    };

    const onSubmitSale = async (event) => {
        event.preventDefault();

        const free_sale_product = formD2.price_sale_free;
        const free_name_product = formD2.description_sale_free;
        const ciValue = formD.ci;
        console.log('CANTIDAD DE PRODUCTOS:', free_sale_product);
        console.log('NOMBRE DE PRODUCTOS DIRECTo:', free_name_product);
        // Validar que la cantidad sea un número positivo         

        console.log('secuencial _bill PROCESS:', secuentialBillIdInProcess);
        console.log('secuencial _bill NEW:', secuentialBillId);
        console.log('passengerStatusValue:', passengerStatusValue);
        console.log('CANTIDAD DE CI PARA VENTA ciValue:', ciValue);
        console.log('CANTIDAD DE CI PARA VENTA secuentialBillCiInProcess:', secuentialBillCiInProcess);
        console.log('LOS DE DATALIST:');
        console.log('STATUS passengerStatusValueDataList:', passengerStatusValueDataList);
        console.log('CI passengerCiValueDataList:', passengerCiValueDataList);
        const formData = new FormData();
        formData.append("passengers_ci", ciValue);
        formData.append("user_id", userId);
        formData.append("price_sale_free", free_sale_product);
        formData.append("description_sale_free", free_name_product);
        formData.append("status_sales_products", 'P');
        formData.append("sale_type", 'Libre');
        if (passengerStatusValue === 'P' && ciValue === secuentialBillCiInProcess) {
        // if (secuentialBillIdInProcess != undefined) {
            formData.append("secuential_bill_id", secuentialBillIdInProcess);
        } else {
            formData.append("secuential_bill_id", secuentialBillId);
        } 
        const currentDate = new Date().toISOString().split('T')[0]; // YYYY-MM-DD
        formData.append("sale_date", currentDate);
        try {
            const response = await axios.post(ApiUrl + 'free-sale-products-create', formData);
            const resp = response.data;
            if (resp.success) {
                toast.success("Producto agregado exitosamente", { position: toast.POSITION.BOTTOM_RIGHT });
                dataList();
                setReloadChild(!reloadChild);
                setformD2({
                    ...formD2,
                    price_sale_free: '', // Limpiar la cantidad después de agregar el producto //classList
                    description_sale_free: '' // Limpiar la cantidad después de agregar el producto //classList
                });
                setButtonDisabled(true);
                setShowForm6(true);

            } else {
                toast.error("El Producto no se ha agregado", { position: toast.POSITION.BOTTOM_RIGHT });
            }

        } catch (error) {
            console.log(error);
            toast.error("" + error + "  !", { position: toast.POSITION.BOTTOM_RIGHT });
        }
        setResetSelect(true);
        setResetSelect(false);


    };

    const dataList = async () => {
        try {
            const ci1 = formD.ci;
            console.log('ci cicicici:', ci1);
            setpassengerCiValue(ci1);

            if (ci1 === null || ci1 === '') {
                console.log('En espera de datos');

            } else {

                const response = await axios.get(ApiUrl + `free-sale-products-list/${userId}/${ci1}`);
                const responseData = response.data;
                setdata(responseData);
                console.log('DATALIST LISTADO FREE:', responseData);
                // Calcular la suma total
                const newTotal = responseData.reduce((acc, item) => acc + parseFloat(item.price_sale_free), 0).toFixed(2);
                setTotal(newTotal);          
                responseData.forEach(item => {
                    console.log('status sss  DataList:', item.status_sales_products);
                    setpassengerStatusValueDataList(item.status_sales_products);
                    setShowForm6(true);
                });
                responseData.forEach(item => {
                    console.log('CIII sssNOTICE DataList:', item.passengers_ci);
                    setpassengerCiValueDataList(item.passengers_ci);
                });
                responseData.forEach(item => {
                    console.log('SF id sss desde dataList:', item.secuential_bill_id);
                    setpassengerSFValueDataList(item.secuential_bill_id);
                    setShowForm6(true);
                });
                responseData.forEach(item => {
                    console.log('TypeSale id sss DATA list:', item.sale_type);
                    setpassengerSaleTypeValueDataList2(item.sale_type);
                });          
            }
        } catch (error) {
            console.error(error);
        }
    };

    const aceptarModal = async () => {
        // Realizar la actualización del estado en el servidor
        try {
            var response1 = '';
            const ciInput = formD.ci;
            const saleProducts = passengerStatusValue;
            if (passengerStatusValueDataList === undefined || ciInput === undefined) {
                toast.error("No existen productos en listado. Por favor, selecciona un producto.", { position: toast.POSITION.BOTTOM_RIGHT });
                return;
            } else {
                // Verificar si las variables tienen valor antes de usarlas
                if (ciInput != null || ciInput != undefined || ciInput != '') {
                    // response1 = await axios.post(`${ApiUrl}sale-product-update-status/${ciInput}`);
                    if (secuentialBillId != undefined) {
                        response1 = await axios.post(`${ApiUrl}free-sale-product-update-status/${ciInput}/${secuentialBillId}`);
                    } else if (secuentialBillIdInProcess != undefined) {
                        response1 = await axios.post(`${ApiUrl}free-sale-product-update-status/${ciInput}/${secuentialBillIdInProcess}`);
                    } else {
                        console.log('No se ha vendido');
                    }

                    console.log('ResponseACCEPT:', response1);
                    toast.success("Venta generada exitosamente", { position: toast.POSITION.BOTTOM_RIGHT });
                    // navigate('/administrativo/ventas-productos');
                } else {
                    toast.error("Error al generar venta", { position: toast.POSITION.BOTTOM_RIGHT });
                }
                // setShowForm5(false);
            }

            // INSERCION DE CUENTA TOTAL 

            console.log('Valor de ciInput Inventario AceptarModal:', ciInput);
            console.log('Valor de saleProducts Inventario AceptarModal:', saleProducts);
            console.log('Valor de saleProducts Inventario AceptarModal DataList:', passengerStatusValueDataList);
            console.log('Valor de secuencial final NEW AceptarModal:', secuentialBillId);
            console.log('Valor de secuencial final PROCESS AceptarModal:', secuentialBillIdInProcess);
            console.log('Valor de secuencial final Dato en Proceso PROCESS AceptarModal:', secuentialBillCiInProcess);
            console.log('Valor de secuencial TOTAL AceptarModal:', total);
            console.log('Valor de secuencial Type Sale AceptarModal FREE2 dataList:', passengerSaleTypeValueDataList2);
            console.log('Valor de secuencial CI AceptarModal:', passengerCiValueDataList);

            const formData = new FormData();
            formData.append("total_price", total);
            // if (passengerStatusValueDataList === 'P' && ciInput === passengerCiValueDataList && passengerSaleTypeValueDataList2 === 'Libre') {
            if (secuentialBillIdInProcess != undefined) {
                formData.append("secuential_bill_id", secuentialBillIdInProcess);
            } else if (secuentialBillId != undefined) {
                formData.append("secuential_bill_id", secuentialBillId);
            } else {
                console.log('No se ha guardado en el TC');
            }

            console.log('Datos enviados al backend AGREGADOSSSS secuential_bill FREE:', Object.fromEntries(formData));
            const response = await axios.post(ApiUrl + 'total-account-sale-products-create', formData);
            const respTC = response.data;
            navigate('/administrativo/ventas-productos');
            console.log('Valor FACT IN :', respTC);

        } catch (error) {
            console.error(error);
            toast.error("Error al generar venta", { position: toast.POSITION.BOTTOM_RIGHT });
        }
    };

    const DeleteSaleModal = async () => {
        // Realizar la actualización del estado en el servidor
        try {
            var response1 = '';
            const ciInput = formD.ci;
            const saleProducts2 = passengerStatusValue;
            console.log('Valor de ciInput ELIMINAR MODAL:', ciInput);
            console.log('Valor de STATUS ELIMINAR MODAL:', saleProducts2);
            console.log('Valor de secuencial final NEW ELIMINAR MODAL:', secuentialBillId);
            console.log('Valor de secuencial final PROCESS ELIMINAR MODAL:', secuentialBillIdInProcess);
            console.log('Valor de saleProducts Inventario AceptarModal DataList:', passengerStatusValueDataList);
            if (passengerStatusValueDataList === undefined || ciInput === undefined) {
                toast.error("No existen productos en listado. Por favor, selecciona un producto.", { position: toast.POSITION.BOTTOM_RIGHT });
                return;
            } else {
                // Verificar si las variables tienen valor antes de usarlas
                if (ciInput != null || ciInput != undefined || ciInput != '') {
                    // response1 = await axios.post(`${ApiUrl}sale-products-delete-complete/${ciInput}`);

                    if (secuentialBillId != undefined) {
                        response1 = await axios.post(`${ApiUrl}free-sale-products-delete-complete/${ciInput}/${secuentialBillId}`);
                    } else if (secuentialBillIdInProcess != undefined) {
                        response1 = await axios.post(`${ApiUrl}free-sale-products-delete-complete/${ciInput}/${secuentialBillIdInProcess}`);
                    } else {
                        console.log('No se ha vendido');
                    }

                    toast.error("Venta cancelada", { position: toast.POSITION.BOTTOM_RIGHT });
                    navigate('/administrativo/ventas-productos');
                    dataList();
                    setReloadChild(!reloadChild);
                    // setShowForm6(false);
                    // setShowForm7(true);
                } else {
                    toast.error("Error al generar venta", { position: toast.POSITION.BOTTOM_RIGHT });
                }
            }
        } catch (error) {
            console.error(error);
            toast.error("Error al generar venta", { position: toast.POSITION.BOTTOM_RIGHT });
        }
    };

    const deleteSaleProduct = async (id) => {
        console.log("ID a eliminar:", id);
        await axios.post(ApiUrl + `free-sale-products-delete/${id}`)
            .then(resp => {
                toast.success("Producto eliminado exitosamente", { position: toast.POSITION.BOTTOM_RIGHT });
                deleteTable();
                dataList();
                setReloadChild(!reloadChild);
            })
            .catch(e => {
                console.log(e);
            });
        setProductToDelete(null);
    };

    const deleteTable = () => {
        const script1 = document.createElement("script");
        script1.src = `/assets/dataTable/datatable-destroy.js`;
        script1.async = true;
        document.body.appendChild(script1);
    }

    const dataList2 = async () => {
        try {
            const response = await axios.get(ApiUrl + `free-sale-products-list-notice`);
            const responseData = response.data;
            setdata(responseData);
            console.log('DATALIST LISTADO NOTICEEE:', responseData);

            // Mostrar solo el estado de cada elemento
            responseData.forEach(item => {
                console.log('status sssNOTICE:', item.status_sales_products);
                setpassengerStatusValue(item.status_sales_products);
            });

            responseData.forEach(item => {
                console.log('CIII sssNOTICE:', item.passengers_ci);
                setpassengerCiValue(item.passengers_ci);
            });

            responseData.forEach(item => {
                console.log('TypeSale id sss NOTICE:', item.sale_type);
                setpassengerSaleTypeValueDataList(item.sale_type);
            });


        } catch (error) {
            console.error(error);
        }
    };

    const handleContinueButtonClick = () => {
        setShowForm3(true);
        setShowForm4(false);
        setShowForm5(true);
    };


    useEffect(() => {
        dataList();
    }, []);

    useEffect(() => {
        dataList2();
        console.log('ererer', passengerStatusValue);
        // Verificar si passengerStatusValue tiene un valor
        if (passengerStatusValue !== undefined) {
            if (passengerStatusValue === 'P') {
                setShowForm2(true);
                setShowForm3(false);
                setShowForm4(true);
                setShowForm5(false);

            }

        } else {
            setShowForm3(true);
            // alert('bbbbb');
        }
    }, [passengerStatusValue]);



    return (
        <div>
            <div className='row'>
                <div className='col-12 '>
                    <div className="card">
                        <div className="card-header">
                            VENTA LIBRE
                        </div>
                    </div>
                    {/* <div className="d-flex justify-content-end"> */}
                    <br></br>
                    <div className="d-flex justify-content">
                        <Link to={'../ventas-productos'}>
                            <button type="button" className="btn btn-secondary btn-lg">Regresar</button>
                        </Link>
                    </div>

                    <br></br>
                    {passengerStatusValue === 'P' && showForm2 && (
                        <center><div>
                            <center><h4>Existe una venta en Proceso con número de cédula:<b> {passengerCiValue}.</b> Para continuar el proceso, dar clic en el botón continuar.</h4></center>
                        </div></center>
                    )}
                    <br></br>
                    {passengerStatusValue === 'P' && showForm4 && (
                        <div>
                            <center><button type="button" className="btn btn-success" onClick={handleContinueButtonClick} >Continuar</button></center>
                        </div>
                    )}

                    {passengerStatusValue === 'P' && showForm5 && (
                        <div>
                            <h5>Digite el número de cédula que muestra la advertencia, en el casillero de "Cédula de Identidad" y dar clic en el botón Buscar.</h5>
                        </div>
                    )}

                    {showForm3 && (
                        <form onSubmit={onSubmit}>
                            <div className='row'>
                                <label>Cédula de Identidad</label>
                                <div className='col-12 col-sm-10'>
                                    <div className="form-group">
                                        <input type="text" name='ci' className="form-control" pattern="\d*" maxLength="10" placeholder='' required value={formD.ci} onChange={onInputChange} />
                                    </div>
                                </div>
                                {showForm7 && (
                                    <div className='col-12 col-sm-2'>
                                        <div className="d-flex align-items-end"> {/* Contenedor para alinear verticalmente en la parte inferior */}
                                            <button type="button" className="btn btn-success" onClick={onSearch}>Buscar</button>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='row'>
                                <div className='col-12 col-sm-8'>
                                    <div className="form-group">
                                        <label >Nombre y Apellido</label>
                                        <input type="text" name='name' className="form-control " placeholder='' required value={formD.name || ''} onChange={onInputChange}></input>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-4'>
                                    <div className="form-group">
                                        <label >Teléfono</label>
                                        <input type="text" name='phone' className="form-control" pattern="\d*" maxLength="10" placeholder='' value={formD.phone || ''} onChange={onInputChange}></input>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-sm-8'>
                                    <div className="form-group">
                                        <label >Dirección</label>
                                        <input type="text" name='address' className="form-control " placeholder='' value={formD.address || ''} onChange={onInputChange}></input>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-4'>
                                    <div className="form-group">
                                        <label >Correo electrónico</label>
                                        <input type="text" name='correo' className="form-control " placeholder='' value={formD.correo || ''} onChange={onInputChange}></input>
                                    </div>
                                </div>
                            </div>
                            {showForm8 && (
                                <div>
                                    <button type="submit" className="btn btn-success" disabled={buttonDisabled}> Guardar </button> &nbsp;
                                    {
                                        showForm9 && (
                                            <button type="button" className="btn btn-success" onClick={onSearchFinalConsumer}>ConsumidorFinal</button>
                                        )}
                                    {/* <button type="submit" className="btn btn-success" disabled={buttonDisabled}> Actualizar </button> &nbsp; */}
                                </div>
                            )}
                        </form>
                    )}

                    {showForm && (
                        <div className="card-body">
                            <hr style={{ margin: '10px 0' }} />
                            <form onSubmit={onSubmitSale}>
                                <div className='row'>
                                    <div className='col-12 '>
                                        <div className='row'>
                                            <div className='col-8'>
                                                <div className="form-group">
                                                    <label >Nombre de producto</label>
                                                    <input type="text" name='description_sale_free' className="form-control " placeholder='' required value={formD2.description_sale_free} onChange={onInputChange2}></input>
                                                </div>
                                            </div>
                                            <div className='col-2'>
                                                <div className="">
                                                    <label className="form-label">Precio</label>
                                                    <input type="number" name='price_sale_free' className="form-control" step="1" placeholder='000' required onChange={onInputChange2} value={formD2.price_sale_free}></input>
                                                </div>
                                            </div>
                                            <div className='col-2'>
                                                <div className="">
                                                    <center><label className="form-label">Añade producto</label></center>
                                                    {/* <center><button type="submit" className="btn btn-success" onClick={() => setResetSelect(false)}> Añadir </button></center> */}
                                                    <center><button type="submit" className="btn btn-success" onClick={() => setResetSelect(false)}> Añadir </button></center>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <div className="card-body table-responsive">
                                <table className='table table-hover ' id="dataTable-ord-col1"  >
                                    <thead>
                                        <tr>
                                            <th>Nombre Producto</th>
                                            <th>Precio unitario</th>
                                            <th>Fecha</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item) => (
                                            <tr key={item.sales_products_id}>
                                                <td>{item.description_sale_free}</td>
                                                <td>{item.price_sale_free}</td>
                                                {/* <td>{item.description_product_sale}</td> */}
                                                <td>{item.created_at}</td>
                                                <td>
                                                    <button className='btn btn-outline-danger' data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => setProductToDelete(item.sales_products_id)}><i className="fas fa-trash-alt" aria-hidden="true"></i>  </button>
                                                </td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan="1"><strong>Total:</strong></td>
                                            <td><strong>{total}</strong></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>

                        </div>
                    )}

                    {showForm6 && (
                        <div>
                            <button data-bs-toggle="modal" data-bs-target="#watchModalSale" className="btn btn-success btn-lg" > Vender </button> &nbsp;
                            <button data-bs-toggle="modal" data-bs-target="#modalTrash" className="btn btn-secondary btn-lg" > Cancelar </button> &nbsp;
                        </div>
                    )}

                </div>
            </div>

            {/* MODAL ELIMINAR PRODUCTO */}
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Eliminar</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            Está seguro que desea eliminar<b>{productSelected.description}</b>
                        </div>
                        <div className="modal-footer">

                            <button type="button" className="btn btn-primary" onClick={() => deleteSaleProduct(productToDelete)} data-bs-dismiss="modal">Aceptar</button>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"  >Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* MODAL GENERAR VENTA */}
            <div className="modal fade" id="watchModalSale" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Generar Solicitud</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            ¿Estás seguro que deseas vender los siguientes productos?<b></b>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={aceptarModal}>Aceptar</button>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"  >Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* MODAL ELIMINAR VENTA */}
            <div className="modal fade" id="modalTrash" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Cancelar Venta</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            ¿Está seguro de cancelar venta?<b></b>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => {
                                DeleteSaleModal();
                                setResetSelect(false);
                            }}>Aceptar</button>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer theme="colored" />
        </div>
    )
}