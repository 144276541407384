import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Cookies from 'universal-cookie/es6';
import { logoutSesion } from '../../store/auth/thunks';
import { LoginScreen } from '../auth/LoginScreen';
import './Navbar.css';
import { requestEnterprise } from '../../functions/requestEnterprise';
import { PreLoader } from '../initialsPages/PreLoader';

const cookies = new Cookies();

export const NavBarUserScreen = () => {
    const token = cookies.get('token');
    const [enterprise, setEnterprise] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false); // Nuevo estado para controlar la carga completa

    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(logoutSesion(token));
        return <LoginScreen />;
    };

    const loadEnterprise = async () => {
        try {
            const enterpriseData = await requestEnterprise();
            setEnterprise(enterpriseData);
            console.log(enterpriseData.img);
            console.log("***********");
            setIsLoaded(true); // Marca como cargado cuando los datos se han cargado
        } catch (error) {
            console.error('Error loading enterprise data:', error);
        }
    };

    useEffect(() => {
        loadEnterprise();
    }, []);

    // Ejecutar el script después de que el componente se haya montado completamente
    useEffect(() => {
        if (isLoaded) {
            // Aquí puedes volver a ejecutar el script de SB Admin
            const sidebarToggle = document.body.querySelector('#sidebarToggle');
            if (sidebarToggle) {
                sidebarToggle.addEventListener('click', event => {
                    event.preventDefault();
                    document.body.classList.toggle('sb-sidenav-toggled');
                    localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
                });
            }
        }
    }, [isLoaded]);

    if (!enterprise) {
        return <PreLoader />;
    }

    return (
        <nav className="sb-topnav navbar navbar-user navbar-expand navbar-dark bg-dark fixed-top ">
            <a className="navbar-brand ps-5" href={enterprise.software_type_id === 1 ? "/" : "#"}>
                <img src={`../assets/images/logos/${enterprise.img}`} alt="" width="auto" height="50vh" />
            </a>
            <button className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" href="#!">
                <i className="fas fa-bars"></i>
            </button>
            <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
                <div className="input-group">
                    {/* <input className="form-control" type="text" placeholder="Search for..." aria-label="Search for..." aria-describedby="btnNavbarSearch" />
                    <button className="btn btn-primary" id="btnNavbarSearch" type="button">
                        <i className="fas fa-search"></i>
                    </button> */}
                </div>
            </form>
            <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="fas fa-user fa-fw"></i>
                    </a>
                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                        <li><a className="dropdown-item" href="#!">Settings</a></li>
                        <li><a className="dropdown-item" href="#!">Activity Log</a></li>
                        <li><hr className="dropdown-divider" /></li>
                        <li><a className="dropdown-item" onClick={handleLogout} href="#!">Logout</a></li>
                    </ul>
                </li>
            </ul>
        </nav>
    );
};
