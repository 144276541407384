import React, { useEffect } from 'react'
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'
import { AdminHomeScreen } from '../components/admin/AdminHomeScreen'
import { NavBarUserScreen } from '../components/navBar/NavBarUserScreen'
import { AdminSidebar } from '../components/sidebar/AdminSidebar'
import Cookies from 'universal-cookie/es6';
import { FooterDashboardScreen } from '../components/footer/FooterDashboardScreen'
import axios from 'axios'
import { ApiUrl } from '../services/ApiRest'
import { loadUser } from '../store/auth/thunks'
import { useDispatch } from 'react-redux'
import { startLoadingTourCatalogue } from '../store/tour_catalogue/thunks'
import { requestRole } from '../functions/requestRole'
// import { AddTour } from '../components/admin/tours/AddTour'


const cookies = new Cookies();

export const AdminRoute = () => {

    var rol = '';
    const dispatch = useDispatch();

    const loadUser123 = async () => {
        rol = await requestRole(cookies.get('uid'))
        
        if (cookies.get('token') && cookies.get('uid')) {
            dispatch(loadUser(cookies.get('uid')));
            dispatch(startLoadingTourCatalogue());
            // console.log("Estoy en administrativo logeado!!!")
        } else {
            console.log('no está logeado')
            //setisLiggedIn(false);
            window.location.href = "/iniciar-sesion";
        }

        if (rol !== '3f7be9c1-997b-42f1-b0b5-58da7de1f37c') {
            return (<h1>No tiene permisos para ingresar</h1>)
    
        }
    }

    useEffect(() => {
        loadUser123();
    }, [])



 





    return (
        <>
            <NavBarUserScreen />
 
            <div id="layoutSidenav" style={{paddingTop:"3.5rem"}}>
                <div id="layoutSidenav_nav">
                    <AdminSidebar />
                </div>
                <div id="layoutSidenav_content">
                    <main>
                        <div className="container-fluid px-3 py-4">
                            <Outlet />

                            {/* <Routes>
                        <Route path="administrativo/home" element={<AdminHomeScreen/>} > </Route>
                        <Route path="administrativo/addTour" element={<AddTour/>} > </Route>
                    </Routes>                         */}
                        </div>
                    </main>

                    <FooterDashboardScreen />
                </div>
            </div>



            {/* <Outlet /> */}
        </>
    )
}
