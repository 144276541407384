import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { ApiStorage, ApiUrl } from '../../../services/ApiRest';
import axios from 'axios';
import CryptoJS from 'crypto-js';

export const SubscriptionsList = () => {
    const [data, setdata] = useState([]);
    const [nameUser, setnameUser] = useState();
    const [lastNameUser, setlastNameUser] = useState();
    const [isLoading, setisLoading] = useState(true);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [buttonDisabled1, setButtonDisabled1] = useState(false);
    const [userId, setuserId] = useState();
    const [formData, setformData] = useState({
        id: null,
        ci: '',
        name: '',
        last_name: '',
        country: '',
        city: '',
        cellphone: '',
        img: '',
        email: '',
        password: '',
        user_status: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setformData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const onSubmit = async (event) => {
        event.preventDefault();
        setButtonDisabled(true);
        const rol_id = 'c642c1be-81a7-4bfb-bc85-c04238b93857';
        try {
            const f = new FormData();
            // const encryptedPassword = CryptoJS.AES.encrypt(formData.ci, formData.ci).toString();
            f.append("ci", formData.ci);
            f.append("name", formData.name);
            f.append("last_name", formData.last_name);
            f.append("country", formData.country);
            f.append("city", formData.city);
            f.append("cellphone", formData.cellphone);
            f.append("rol", rol_id);
            f.append("email", formData.email);
            f.append("password", formData.ci);
            f.append("user_status", 1);
            console.log('Datos enviados al backend AGREGADOSSSS secuential_bill NUEVO CLIENT:', Object.fromEntries(f));
            const response = await axios.post(ApiUrl + 'subscriptions-add', f);
            const resp = response.data;
            console.log('RESPUESTA DEL BACKEND', response);
            setformData({
                ci: '',
                name: '',
                last_name: '',
                country: '',
                city: '',
                cellphone: '',
                img: '',
                email: '',
                password: '',
            });
            if (resp.success) {
                toast.success("Suscripción agregada exitosamente", { position: toast.POSITION.BOTTOM_RIGHT })
                dataListSubscription();
            } else {
                toast.error("Suscripción errónea", { position: toast.POSITION.BOTTOM_RIGHT });
            }
        } catch (error) {
            console.log("Error al enviar los datos:", error);
            toast.error("" + error + "!", { position: toast.POSITION.BOTTOM_RIGHT });
        } finally {
            setButtonDisabled(false);
        };
    };

    const onSubmitUpdate = async (event) => {
        event.preventDefault();
        setButtonDisabled(true);
        const rol_id = 'c642c1be-81a7-4bfb-bc85-c04238b93857';
        const f = new FormData();
        f.append("ci", formData.ci);
        f.append("name", formData.name);
        f.append("last_name", formData.last_name);
        f.append("country", formData.country);
        f.append("city", formData.city);
        f.append("cellphone", formData.cellphone);
        f.append("rol", rol_id);
        f.append("email", formData.email);
        f.append("user_status", 1);
        try {
            const response = await axios.post(ApiUrl + `subscriptions-update/${formData.id}`, f);
            const resp = response.data;
            if (resp.success) {
                toast.success("Suscripción actualizada exitosamente", { position: toast.POSITION.BOTTOM_RIGHT });
                dataListSubscription();
            } else {
                toast.error("Suscripción no actualizada", { position: toast.POSITION.BOTTOM_RIGHT });
            }
            setformData({
                ci: '',
                name: '',
                last_name: '',
                country: '',
                city: '',
                cellphone: '',
                img: '',
                email: '',
            });
        } catch (error) {
            console.log(error);
            toast.error("" + error + "!", { position: toast.POSITION.BOTTOM_RIGHT });
        } finally {
            setButtonDisabled(false);
        };
    };

    const passwordUpdate = async (event) => {
        event.preventDefault();
        setButtonDisabled1(true);
        const f = new FormData();
        // const encryptedPassword = CryptoJS.AES.encrypt(formData.ci, formData.ci).toString();
        f.append("password", formData.ci);
        try {
            const response = await axios.post(ApiUrl + `password-update/${formData.id}`, f);
            const resp = response.data;
            if (resp.success) {
                toast.success("Contraseña actualizada exitosamente", { position: toast.POSITION.BOTTOM_RIGHT });
                dataListSubscription();
            } else {
                toast.error("Contraseña no actualizada", { position: toast.POSITION.BOTTOM_RIGHT });
            }
        } catch (error) {
            console.log(error);
            toast.error("" + error + "!", { position: toast.POSITION.BOTTOM_RIGHT });
        } finally {
            setButtonDisabled1(false);
        };
    };

    const dataListSubscription = async () => {
        try {
            const response = await axios.get(ApiUrl + `subscriptions-list`);
            const responseData = response.data;
            console.log('DATOS:', responseData);
            // setdataTransactions(responseData);
            if (Array.isArray(responseData)) {
                setdata(responseData);
            } else {
                console.error("La respuesta no es un arreglo:", responseData);
                setdata([]); // Evita errores si la API devuelve algo inesperado
            }
            const script = document.createElement("script");
            script.src = `/assets/dataTable/dataTable.js`;
            script.async = true;
            document.body.appendChild(script);
            setisLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    const deleteSubscription = async (id) => {
        try {
            const response = await axios.post(ApiUrl + `subscriptions-delete/${id}`);
            const resp = response.data;
            if (resp.success === true) {
                toast.success("Suscripción eliminada exitosamente", { position: toast.POSITION.BOTTOM_RIGHT });
                dataListSubscription();
            } else {
                toast.error("Suscripción no eliminada", { position: toast.POSITION.BOTTOM_RIGHT });
            }
        } catch (error) {
            console.log(error);
            toast.error("" + error + "  !", { position: toast.POSITION.BOTTOM_RIGHT });
        }
    };

    useEffect(() => {
        dataListSubscription();
    }, [])

    if (isLoading) {
        return (
            <div>
                <h5>Cargando Datos...</h5>
            </div>
        )
    }

    return (
        <>
            <div>
                <div className='row'>
                    <div className='col-12 '>
                        <div className="card">
                            <div className="card-header">
                                <div className='row'>
                                    <div className='col-12 col-md-6'>
                                        <b> SUSCRIPCIONES </b>
                                    </div>
                                    <div className='col-12 col-md-6 ' style={{ textAlign: 'right' }}>
                                        <button className='btn btn-success btn-sm' data-bs-toggle="modal" data-bs-target="#addSuscripcion" ><b>CREAR SUSCRIPCIÓN </b><i className="fa fa-plus" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                            </div>
                            <br></br>
                            <div className="card-body table-responsive">
                                <table className='table table-hover' id="dataTable" >
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Cédula de identidad</th>
                                            <th>Nombres y Apellidos</th>
                                            <th>País</th>
                                            <th>Ciudad</th>
                                            <th>Contacto</th>
                                            <th>email</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Array.isArray(data) && data.map((item, index) => (
                                                <tr key={item.id}>
                                                    <td >{index + 1}</td>
                                                    <td >{item.ci}</td>
                                                    <td >{item.name} {item.last_name}</td>
                                                    <td>{item.country}</td>
                                                    <td>{item.city}</td>
                                                    <td>{item.cellphone}</td>
                                                    <td>{item.email}</td>
                                                    <td>
                                                        <button className='btn btn-outline-primary' data-bs-toggle="modal" data-bs-target="#suscripcionEdit" onClick={() => { setformData({ id: item.id, ci: item.ci, name: item.name, last_name: item.last_name, country: item.country, city: item.city, cellphone: item.cellphone, email: item.email }); setnameUser(item.name); setlastNameUser(item.last_name); }}><i className="fa fa-edit"></i></button>
                                                        <button className='btn btn-outline-danger' data-bs-toggle="modal" data-bs-target="#suscripcionDelete" onClick={() => { setuserId(item.id) }}><i className="fas fa-trash-alt"></i></button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                {/* AGREGAR SUSCRIPCIONES */}
                <div className="modal fade" id="addSuscripcion" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={onSubmit}>
                                <div className="modal-header">
                                    <p className="modal-title" id="exampleModalLabel"><b>AGREGAR NUEVA SUSCRIPCIÓN</b></p>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="card-body">
                                        <div className='row'>
                                            <div className='col-12 col-sm-12'>
                                                <div className="form-group">
                                                    <label >Cédula de identificación</label>
                                                    <input type="text" name='ci' className="form-control" required value={formData.ci} onChange={(e) => { let value = e.target.value; value = value.replace(/[^0-9]/g, ''); setformData({ ...formData, ci: value }); }} maxLength="10" pattern="\d{10}"></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <div className="form-group">
                                                    <label >Nombres</label>
                                                    <input type="text" name='name' className="form-control" placeholder='' required onChange={handleChange} value={formData.name}></input>
                                                </div>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <div className="form-group">
                                                    <label >Apellidos</label>
                                                    <input type="text" name='last_name' className="form-control" placeholder='' required onChange={handleChange} value={formData.last_name}></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <div className="form-group">
                                                    <label >País</label>
                                                    <input type="text" name='country' className="form-control" placeholder='' required onChange={handleChange} value={formData.country}></input>
                                                </div>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <div className="form-group">
                                                    <label >Ciudad</label>
                                                    <input type="text" name='city' className="form-control" placeholder='' required onChange={handleChange} value={formData.city}></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <div className="form-group">
                                                    <label >Contacto</label>
                                                    <input type="text" name='cellphone' className="form-control" placeholder='' required onChange={handleChange} value={formData.cellphone}></input>
                                                </div>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <div className="form-group">
                                                    <label >Email</label>
                                                    <input type="text" name='email' className="form-control" placeholder='' required onChange={handleChange} value={formData.email}></input>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-primary" disabled={buttonDisabled}> Aceptar</button> &nbsp;
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/* EDITAR SUSCRIPCIONES */}
                <div className="modal fade" id="suscripcionEdit" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel"><b>EDITAR SUSCRIPCIÓN</b></h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={onSubmitUpdate}>
                                    <div className="modal-body">
                                        <div className="card-body">
                                            <div className='row'>
                                                <div className='col-12 col-sm-12'>
                                                    <div className="form-group">
                                                        <label >Cédula de identificación</label>
                                                        <input type="text" name='ci' className="form-control" required value={formData.ci || ""} onChange={(e) => { let value = e.target.value; value = value.replace(/[^0-9]/g, ''); setformData({ ...formData, ci: value }); }} maxLength="10" pattern="\d{10}"></input>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12 col-sm-6'>
                                                    <div className="form-group">
                                                        <label >Nombres</label>
                                                        <input type="text" name='name' className="form-control" placeholder='' required onChange={handleChange} value={formData.name || ""}></input>
                                                    </div>
                                                </div>
                                                <div className='col-12 col-sm-6'>
                                                    <div className="form-group">
                                                        <label >Apellidos</label>
                                                        <input type="text" name='last_name' className="form-control" placeholder='' required onChange={handleChange} value={formData.last_name || ""}></input>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12 col-sm-6'>
                                                    <div className="form-group">
                                                        <label >País</label>
                                                        <input type="text" name='country' className="form-control" placeholder='' required onChange={handleChange} value={formData.country || ""}></input>
                                                    </div>
                                                </div>
                                                <div className='col-12 col-sm-6'>
                                                    <div className="form-group">
                                                        <label >Ciudad</label>
                                                        <input type="text" name='city' className="form-control" placeholder='' required onChange={handleChange} value={formData.city || ""}></input>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12 col-sm-6'>
                                                    <div className="form-group">
                                                        <label >Contacto</label>
                                                        <input type="text" name='cellphone' className="form-control" placeholder='' required onChange={handleChange} value={formData.cellphone || ""}></input>
                                                    </div>
                                                </div>
                                                <div className='col-12 col-sm-6'>
                                                    <div className="form-group">
                                                        <label >Email</label>
                                                        <input type="text" name='email' className="form-control" placeholder='' required onChange={handleChange} value={formData.email || ""}></input>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-success" data-bs-toggle="modal" data-bs-target="#resetPassword"> Resetar contraseña</button> &nbsp;
                                        <button type="submit" className="btn btn-primary" data-bs-dismiss="modal" disabled={buttonDisabled}> Aceptar</button> &nbsp;
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ELIMINAR SUSCRIPCIONES */}
                <div className="modal fade" id="suscripcionDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">ELIMINAR SUSCRIPCIÓN</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                ¿Está seguro que desea eliminar?
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => deleteSubscription(userId)}>Aceptar</button>
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* RESETEAR CONTRASEÑA */}
                <div className="modal fade" id="resetPassword" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">RESETEAR CONTRASEÑA</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                ¿Está seguro que desea resetear la contraseña de usuario <b>{nameUser} {lastNameUser}</b>? 
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={passwordUpdate} disabled={buttonDisabled1}>Aceptar</button>
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer theme="colored" />
            </div >
        </>
    )
}
