// import React from 'react';
import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { ApiUrl } from '../../services/ApiRest';
import axios from 'axios';
import Cookies from 'universal-cookie/es6';

const cookies = new Cookies();
const userId = cookies.get('uid');


export const SubscriberProfileEdit = () => {
    const [data, setdata] = useState([]);
    const [isLoading, setisLoading] = useState(true);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [passwordMatch, setPasswordMatch] = useState(null);
    const [passwords, setPasswords] = useState({
        newPassword: "",
        confirmPassword: "",
    });
    const [formData, setformData] = useState({
        id: null,
        ci: '',
        name: '',
        last_name: '',
        country: '',
        city: '',
        email: '',
        password: '',
        user_status: ''
    });

    const onSubmitUpdate = async (event) => {
        event.preventDefault();
        setButtonDisabled(true);
        const rol_id = 'c642c1be-81a7-4bfb-bc85-c04238b93857';
        const f = new FormData();
        f.append("ci", formData.ci);
        f.append("name", formData.name);
        f.append("last_name", formData.last_name);
        f.append("country", formData.country);
        f.append("city", formData.city);
        f.append("cellphone", formData.cellphone);
        f.append("rol", rol_id);
        f.append("email", formData.email);
        f.append("user_status", 1);
        try {
            const response = await axios.post(ApiUrl + `subscriptions-update/${formData.id}`, f);
            const resp = response.data;
            if (resp.success) {
                toast.success("Suscripción actualizada exitosamente", { position: toast.POSITION.BOTTOM_RIGHT });
                dataListSubscription();
            } else {
                toast.error("Suscripción no actualizada", { position: toast.POSITION.BOTTOM_RIGHT });
            }
        } catch (error) {
            console.log(error);
            toast.error("" + error + "!", { position: toast.POSITION.BOTTOM_RIGHT });
        } finally {
            setButtonDisabled(false);
        };
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setformData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handlePasswordInput = (e) => {
        const { name, value } = e.target;
        setPasswords((prevPasswords) => ({
            ...prevPasswords,
            [name]: value,
        }));

        // Verificar si las contraseñas coinciden
        if (name === "confirmPassword" || name === "newPassword") {
            setPasswordMatch(
                e.target.name === "newPassword"
                    ? value === passwords.confirmPassword
                    : value === passwords.newPassword
            );
        }
    };

    const handlePasswordChange = async (e) => {
        e.preventDefault();
        setButtonDisabled(true);
        if (!passwordMatch) {
            toast.error("Las contraseñas no coinciden.", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            return;
        }
        const f = new FormData();
        f.append("newPassword", passwords.newPassword);
        f.append("password", passwords.confirmPassword);
        try {
            const response = await axios.post(
                `${ApiUrl}password-update-subscriber-profile/${userId}`, f
            );
            if (response.data.success) {
                toast.success("Contraseña actualizada exitosamente.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            } else {
                toast.error("No se pudo actualizar la contraseña.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        } catch (error) {
            console.error(error);
            toast.error("Error al actualizar la contraseña.", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        } finally {
            setButtonDisabled(false);
        };
    };

    const dataListSubscription = async () => {
        try {
            const response = await axios.get(ApiUrl + `subscriber-edit-profile-list/${userId}`);
            const responseData = response.data;
            if (Array.isArray(responseData) && responseData.length > 0) {
                const dataUser = responseData[0]; // Accede al primer elemento del array
                // Actualiza los valores de formData con los datos recibidos
                setformData({
                    id: dataUser.id || '',
                    ci: dataUser.ci || '',
                    name: dataUser.name || '',
                    last_name: dataUser.last_name || '',
                    country: dataUser.country || '',
                    city: dataUser.city || '',
                    cellphone: dataUser.cellphone || '',
                    email: dataUser.email || '',
                    password: '', // Deja en blanco si no lo recibes
                    user_status: dataUser.user_status || '',
                });
                setdata(responseData); // Opcional, según sea necesario
            } else {
                console.error("La respuesta no es un arreglo:", responseData);
                setdata([]); // Evita errores si la API devuelve algo inesperado
            }
            setisLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        dataListSubscription();
    }, [])

    if (isLoading) {
        return (
            <div>
                <h5>Cargando Datos...</h5>
            </div>
        )
    }

    return (
        <div>
            <div className='row'>
                <div className='col-12 '>
                    <div className="card">
                        <div className="card-header">
                            <div className='row'>
                                <div className='col-12 col-md-6'>
                                    <b> SUSCRIPCIONES </b>
                                </div>
                                <div className='col-12 col-md-6 ' style={{ textAlign: 'right' }}>
                                    <button className='btn btn-success btn-sm' data-bs-toggle="modal" data-bs-target="#addSuscripcion" ><i className="fas fa-key" aria-hidden="true"></i> <b>EDITAR CONTRASEÑA </b></button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <form onSubmit={onSubmitUpdate}>
                                <div className="modal-body">
                                    <div className="card-body">
                                        <div className='row'>
                                            <div className='col-12 col-sm-12'>
                                                <div className="form-group">
                                                    <label >Cédula de identificación</label>
                                                    <input type="text" name='ci' className="form-control" required value={formData.ci || ""}
                                                        onChange={(e) => {
                                                            const value = e.target.value.replace(/[^0-9]/g, '');
                                                            setformData((prevData) => ({ ...prevData, ci: value }));
                                                        }} maxLength="10" pattern="\d{10}"></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <div className="form-group">
                                                    <label >Nombres</label>
                                                    <input type="text" name='name' className="form-control" placeholder='' required onChange={handleChange} value={formData.name || ""}></input>
                                                </div>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <div className="form-group">
                                                    <label >Apellidos</label>
                                                    <input type="text" name='last_name' className="form-control" placeholder='' required onChange={handleChange} value={formData.last_name || ""}></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <div className="form-group">
                                                    <label >País</label>
                                                    <input type="text" name='country' className="form-control" placeholder='' required onChange={handleChange} value={formData.country || ""}></input>
                                                </div>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <div className="form-group">
                                                    <label >Ciudad</label>
                                                    <input type="text" name='city' className="form-control" placeholder='' required onChange={handleChange} value={formData.city || ""}></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6'>
                                                <div className="form-group">
                                                    <label >Email</label>
                                                    <input type="text" name='email' className="form-control" placeholder='' required onChange={handleChange} value={formData.email || ""}></input>
                                                </div>
                                            </div>
                                            <div className='col-12 col-sm-3'>
                                                <div className="form-group">
                                                    <label >Contacto</label>
                                                    <input type="text" name='cellphone' className="form-control" placeholder='' required onChange={handleChange} value={formData.cellphone || ""}></input>
                                                </div>
                                            </div>
                                            <div className='col-12 col-sm-3 d-flex align-items-end'>
                                                <div className="form-group w-100">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary w-100"
                                                        data-bs-dismiss="modal"
                                                        disabled={buttonDisabled}
                                                    >
                                                        Aceptar
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="modal-footer">
                                    <button type="submit" className="btn btn-primary" data-bs-dismiss="modal" disabled={buttonDisabled}> Aceptar</button> &nbsp;
                                </div> */}
                            </form>

                        </div>
                    </div>
                </div>
            </div>
            <div className="card-body table-responsive">
                <table className='table table-hover'>
                    <thead>
                        <tr>
                            <th>Cédula de identidad</th>
                            <th>Nombres y Apellidos</th>
                            <th>País</th>
                            <th>Ciudad</th>
                            <th>Contacto</th>
                            <th>email</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Array.isArray(data) && data.map((item, index) => (
                                <tr key={item.id}>
                                    <td >{item.ci}</td>
                                    <td >{item.name} {item.last_name}</td>
                                    <td>{item.country}</td>
                                    <td>{item.city}</td>
                                    <td>{item.cellphone}</td>
                                    <td>{item.email}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>

            {/* CAMBIO DE CONTRASEÑA */}
            <div className="modal fade" id="addSuscripcion" tabIndex="-1" aria-labelledby="addSuscripcionLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addSuscripcionLabel">Cambiar Contraseña</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handlePasswordChange}>
                                <div className="form-group">
                                    <label>Nueva Contraseña</label>
                                    <input type="password" name="newPassword" className="form-control" placeholder="Ingrese la nueva contraseña" required onChange={handlePasswordInput} />
                                </div>
                                <div className="form-group mt-3">
                                    <label>Confirmar Contraseña</label>
                                    <input type="password" name="confirmPassword" className="form-control" placeholder="Confirme la nueva contraseña" required onChange={handlePasswordInput} />
                                </div>
                                <div className="mt-2">
                                    {passwordMatch !== null && (
                                        <small
                                            className={`text-${passwordMatch ? "success" : "danger"}`}
                                        >
                                            {passwordMatch
                                                ? "✔ Las contraseñas coinciden."
                                                : "✘ Las contraseñas no coinciden."}
                                        </small>
                                    )}
                                </div>
                                <div className="modal-footer mt-4">
                                    <button type="submit" className="btn btn-primary" disabled={buttonDisabled || !passwordMatch}> Guardar Cambios </button>
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" > Cerrar </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer theme="colored" />
        </div>
    );
};